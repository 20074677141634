<template>
<!-- 迪荡湖街道 - 健身人群图像 右边 -->
    <div style="position:relative;">
		<div class="container darkBack">
			<!-- <div class="whiteFont font18" >{{region}}体育人群画像</div> -->
			<div class="whiteFont font18" >体育人群画像</div>
			<!-- 上方两个环形图 -->
			<div class="flex marginTop40">
				<!-- 左侧图表 -->
				<div class="width50">
					<div class="blueFont font16">● 运动年龄分布</div>
					<div id="sportAge"></div>
				</div>
				<!-- 右侧图表 -->
				<div>
					<div class="blueFont font16">● 运动时间分布</div>
					<div id="sportTime"></div>
				</div>
			</div>
			<!-- 下方图表 -->
			<div style="margin-top: 0rem;" class="flex">
				<!-- 左侧图表 -->
				<div class="width50">
					<div class="blueFont font16">● 男女比例</div>
					<div class="imgGroup">
						<img class="manPic" src="../../../assets/images/man.png" >
						<img class="womanPic" src="../../../assets/images/woman.png" >
					</div>
					<div class="legendText">
						<span>男</span>
						<span>女</span>
					</div>
					<div class="proportion">
						<div class="man">{{ratio.man}}</div>
						<div class="woman">{{ratio.woman}}</div>
					</div>
				</div>
				<!-- 右侧图表 -->
				<div style="width: 2.2rem;">
					<div class="blueFont font16">● 运动喜好</div>
					<div id="like"></div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import bus from '/src/assets/js/bus.js'

	import countTo from 'vue-count-to';
	export default {
		components: { countTo },
		data() {
			return{
				region:'迪荡街道',


				ratio:{
					man:'53%',
					woman:'47%',	
				},

				image1:require("../../../assets/images/man.png"),
				image2:require("../../../assets/images/woman.png"),

				age:[
					{value: 1048, name: '青少年'},
					{value: 735, name: '青年'},
					{value: 580, name: '中年'},
					{value: 484, name: '老年'},
				],

				timer:[
					{value: 1048, name: '0-8点'},
					{value: 735, name: '8-13点'},
					{value: 580, name: '13-18点'},
					{value: 484, name: '18-24点'},
				],

				like: {
					yAxis:['跑步', '篮球', '乒乓球', '羽毛球', '足球'],
					data:[]
				}
			}
		},
		methods:{
			// 绘制运动年龄图
			drawSportAge(id,number){
				// let myChart = this.$echarts.init(document.getElementById(id));
				let myChart = this.$echarts.getInstanceByDom(document.getElementById(id))
				if (myChart == null) { // 如果不存在，就进行初始化
					myChart = this.$echarts.init(document.getElementById(id));
				}
				
				let option = {
					// legend: {
					// 	bottom:0,
					// 	left: 'center',
					// 	itemWidth:15,
					// 	itemHeight:15,
					// 	itemGap:5,
					// 	textStyle: { //图例文字的样式
					// 		color: '#7EA7E2',
					// 		fontSize: 14
					// 	},
					// },
					series: [
						{
							type: 'pie',
							radius: ['40%', '50%'],
							 // 在容器中的位置
							center:['50%','40%'],
							avoidLabelOverlap: false,
							labelLine: {
								show: true,
								//设置延长线的长度
								normal: {
									length: 15,//设置延长线的长度
									length2: 10,//设置第二段延长线的长度
									lineStyle: {
										color: "#ffffff"  // 改变标示线的颜色
									}
								},
							}, 
							 // 字体颜色及大小
							label:{
								color:'#D2F8FF',
								textStyle:{fontSize:12},
							},
							color:['#1D77D2','#E35D68','#1BBA79','#F45E23'],
							data: number
						}
					]
				};
				
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  myChart.resize();
				});
			},
			// 绘制运动时间分布图
			drawSportTime(number){
				// let myChart = this.$echarts.init(document.getElementById("sportTime"));
				let myChart = this.$echarts.getInstanceByDom(document.getElementById("sportTime"))
				if (myChart == null) { // 如果不存在，就进行初始化
					myChart = this.$echarts.init(document.getElementById("sportTime"));
				}

				let option = {
					// legend: {
					// 	bottom:0,
					// 	left: 'center',
					// 	itemWidth:15,
					// 	itemHeight:15,
					// 	itemGap:5,
					// 	textStyle: { //图例文字的样式
					// 		color: '#7EA7E2',
					// 		fontSize: 14
					// 	},
					// },
					series: [
						{
							type: 'pie',
							radius: ['40%', '50%'],
							 // 在容器中的位置
							center:['50%','40%'],
							avoidLabelOverlap: false,
							labelLine: {
								show: true,
								//设置延长线的长度
								normal: {
									length: 15,//设置延长线的长度
									length2: 10,//设置第二段延长线的长度
									lineStyle: {
										color: "#ffffff"  // 改变标示线的颜色
									}
								},
							}, 
							 // 字体颜色及大小
							label:{
								color:'#D2F8FF',
								textStyle:{fontSize:12},
							},
							color:['#1D77D2','#E35D68','#1BBA79','#F45E23'],
							data: number.data
						}
					]
				};
				
				// 使用刚指定的配置项和数据显示图表。
				myChart.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
				  myChart.resize();
				});
			},
			// 绘制运动喜好图表
			// drawSportLike(number){
			// 	let myChart = this.$echarts.init(document.getElementById("like"));
			// 	let data = number.data;
			// 	for (let i = 0; i < 5; ++i) {
			// 	    data.push(Math.round(Math.random() * 200));
			// 	}
				
			// 	let option = {
			// 	    xAxis: {
			// 	        max: 'dataMax',
			// 			// 不显示网格线
			// 			splitLine:{show: false},
			// 			// 不显示刻度值
			// 			axisLabel:{show:false}
			// 	    },
            //         grid: {         // 间距
            //             left: '0%',  
            //             // right: '16%', 
            //             top:'2%', 
            //             bottom: '1%',  
            //             containLabel: true,
            //         },
			// 	    yAxis: {
			// 	        type: 'category',
			// 	        data: number.yAxis,
			// 	        inverse: true,
			// 	        animationDuration: 300,
			// 	        animationDurationUpdate: 300,
			// 			//y轴显示及颜色
			// 			axisLine:{show:false,},
			// 			// 刻度不显示
			// 			axisTick:{show:false},
			// 			// y轴字体样式
			// 			axisLabel:{
			// 				color:'#7EA7E2',
			// 				fontSize:'0.14rem'
			// 			},
			// 	    },
			// 	    series: [{
			// 			// 实时排序
			// 	        realtimeSort: true,
			// 	        type: 'bar',
			// 	        data: data,
			// 			// 柱子宽度
			// 			barWidth:'36%',
			// 			// 柱子间距
			// 			barCategoryGap:10,
			// 			// 数据显示
			// 	        label: {
			// 	            show: true,
			// 	            position: 'top',
			// 				color:"#F1F1F1",
			// 	            valueAnimation: true,
            //                 fontSize:'0.14rem'
			// 	        },
			// 			itemStyle: {
			// 				//通常情况下：
			// 				normal: {
			// 					//每个柱子的颜色即为colorList数组里的每一项，如果柱子数目多于colorList的长度，则柱子颜色循环使用该数组
			// 					color: params => {
			// 						var colorList = [
			// 							['#F66901', '#EFDC29'],
			// 							['#278D65', '#61C367'],
			// 							['#2A54B4', '#1DCAFE'],
			// 							['#754ABF', '#F55273'],
			// 							['#BC3535', '#F8617D'],
			// 						];
			// 						var index = params.dataIndex;
			// 						if (params.dataIndex >= colorList.length) {
			// 							index = params.dataIndex - colorList.length;
			// 						}
			// 						return new this.$echarts.graphic.LinearGradient(0, 0, 1, 0,
			// 							[{
			// 									offset: 0,
			// 									color: colorList[index][0]
			// 								},
			// 								{
			// 									offset: 1,
			// 									color: colorList[index][1]
			// 								}
			// 							]);
			// 					}
			// 				},
			// 			},
			// 	    }],
			// 	    animationDuration: 0,
			// 	    animationDurationUpdate: 3000,
			// 	    animationEasing: 'linear',
			// 	    animationEasingUpdate: 'linear'
			// 	};
				
			// 	// 使用刚指定的配置项和数据显示图表。
			// 	myChart.setOption(option);
			// 	// 图表自动缩放
			// 	window.addEventListener("resize",function(){
			// 	  myChart.resize();
			// 	});
			// 	function run () {
			// 	    var data = option.series[0].data;
			// 	    for (var i = 0; i < data.length; ++i) {
			// 	        if (Math.random() > 0.9) {
			// 	            data[i] += Math.round(Math.random() * 2000);
			// 	        }
			// 	        else {
			// 	            data[i] += Math.round(Math.random() * 200);
			// 	        }
			// 	    }
			// 	    myChart.setOption(option);
			// 	}
				
			// 	setTimeout(function() {
			// 	    run();
			// 	}, 0);
			// 	setInterval(function () {
			// 	    run();
			// 	}, 3000);
			// },

			// 获取年龄、性别比、喜好
			drawSportLike(data){
				let that = this;
				// let canye = that.$echarts.init(document.getElementById("like"));
				let canye = this.$echarts.getInstanceByDom(document.getElementById("like"))
				if (canye == null) { // 如果不存在，就进行初始化
					canye = this.$echarts.init(document.getElementById("like"));
				}
				

				let option = {
					xAxis: {
						// max: 'dataMax',
						show:true,
						type: 'value',
						splitNumber: 6,
						"axisLine":{     //x轴坐标轴
							"show":false,
						},
						axisLabel:{
							show: false,
						},
						
						axisTick: {		//x轴刻度线
							show:false
						},
						splitLine: {            // 网格线的颜色
							show: true,
							lineStyle:{
								color: '#2D94D040',
								width: 1,
								type: 'solid',
							}
					　　}
					},
					grid: {         // 间距
						left: '8%',  
						right: '4%', 
						top:'4%', 
						bottom: '4%',  
						containLabel: true,
					},
					yAxis: {
						type: 'category',
						data: data.yAxis,
						inverse: true,              // 上下反向
						// animationDuration: 300,
						// animationDurationUpdate: 300,
						// max: number.max,          // y轴显示显示最大的条数 
						axisLine:{              // y轴左侧的样式
							show:true,
							lineStyle: {
								color: '#2D94D0',
							}
						},
						splitLine:{show: false},
						axisTick: {                 // y轴刻度线
							show:false,
							alignWithLabel: true,
						},
						splitArea : {show : false}, 
						axisLabel: {               // y轴字体的颜色
							// margin: 176,//刻度标签与轴线之间的距离
							interval:0,//横轴信息全部显示 
							textStyle: {
								show:true,
								fontFamily:'微软雅黑',
								color: "#7EA7E2",
								fontSize: '0.13rem',
								// align: 'left'
							},                           
						},
					},
					series: [{
						// realtimeSort: true,
						// name: 'X',
						type: 'bar',
						barWidth : '40%',              // 柱子的宽度
						data: data.data,
						itemStyle: {
							normal: {
								color: that.$echarts.graphic.LinearGradient(0, 0 , 1, 0, [{
									offset: 0,
									color: "#2D94D0" // 0% 处的颜色
								}, {
									offset: 1,
									color: "#3AE6CE" // 100% 处的颜色
								}], false)
							}
						},
						label: {
							formatter: "{c}",
							show: true,
							position: 'right',
							valueAnimation: true,
							color: '#3AE6CE',    // 柱状图上字体的颜色
							fontSize:'0.13rem',
						},
					}],
					legend: {
						show: true
					},
					animationDuration: 0,
					animationDurationUpdate: 3000,
					animationEasing: 'linear',
					animationEasingUpdate: 'linear'
				};
				// 使用刚指定的配置项和数据显示图表。
				canye.setOption(option);
				// 图表自动缩放
				window.addEventListener("resize",function(){
					canye.resize();
				});
			},
			// 获取用户画像
			getUserPhoto(){
				let that = this;
				// let data = {
				//   area:window.communityname
				// }
				let url = window.appHost+`/api/v1/screen/Area/user_analysis?street=${window.clickstreet}`
				let result = window.ajaxRequest(url)
				// console.log(result);

				// 获取性别比
				that.ratio.man = result.data.sex[0].parent+"%";
				that.ratio.woman = result.data.sex[1].parent+"%";

				// 绘制年龄图
				that.age = [];
				result.data.age.forEach((item,index)=>{
					that.age.push({
					value:item.parent,
					name:item.title
					})
				})
				that.drawSportAge("sportAge",that.age);

				// 绘制喜好图
				that.like = {
				yAxis: [],
				data: []
				}
				result.data.like.forEach((item,index)=>{
				that.like.yAxis.push(item.title);
				that.like.data.push(item.num);
				})
				that.drawSportLike(that.like);

				// 绘制时间分布
				that.timer = [];
				result.data.time.forEach((item,index)=>{
					that.timer.push({
					value:item.parent,
					name:item.title
					})
				})
				that.drawSportAge("sportTime",that.timer);
			},

		},
		mounted() {
			let that = this;
			// that.drawSportAge(that.age);
			// that.drawSportTime(that.timer);
			// that.drawSportLike(that.like);

			that.getUserPhoto()
			bus.$on('now_street',res=>{
				// console.log(res)
				that.getUserPhoto()

			})
		},
		beforeDestroy(){
			bus.$off('now_street')
		}
	}
</script>

<style scoped>
	.container{
		padding: 0.2rem;
		width: 5.92rem;
		height: 5.9rem;
		position: relative;
	}
	.font18{ font-size: 0.18rem; }
	.marginTop40{ margin-top: 0.4rem; }
	.width50{width: 2.48rem;}
	.flex{
		display: flex;
		justify-content: space-between;
	}
	.blueFont{
		color:#20D7FE;
		font-size: 0.16rem;
	}
	#sportAge , #sportTime{
		width: 2.20rem;
		height: 2.20rem;
	}
	.imgGroup{
		width: 1.85rem;
		display: flex;
		justify-content: space-between;
		padding-left: 0.1rem;
		margin-top: 20%;
	}
	.manPic{
		width: 0.39rem;
		height: 0.46rem;
	}
	.womanPic{
		width: 0.43rem;
		height: 0.46rem;
	}
	.proportion{
		display: flex;
		padding-left: 0.1rem;
	}
	.man , .woman{
		color: white;
		text-align: center;
		font-size: 0.14rem
	}
	.man{
		width: 0.92rem;
		background-color: #0E83C8;
	}
	.woman{
		width: 0.92rem;
		background-color: #E88A2B;
	}
	.legendText{
		display: flex;
		justify-content: space-between;
		color: #7B8CB7;
		font-size: 0.12rem;
		width: 1.85rem;
		margin: 0.15rem 0rem 0.05rem 0.1rem;
	}
	#like{
		width:2.40rem;
		height:2.30rem;
	}
</style>